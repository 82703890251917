.step {
    background: #639;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    text-align: center;
    height: 10vh;
    margin-top: 10px;
    margin-inline: 10px;
 }
 
 
 .step span {
     font-size: 8px;
 }
 
 .step span,
 .share_h1 {
   color: #fff;
   margin-top: 0;
   font-family: inherit;
   font-weight: 400;
   width: 30px;
 }
 
 .open_acct_shared {
    background: #f6f7fb;
     display: flex;
     padding-top: 5vh;
     padding-right: 2px;
     justify-content: center;
     flex-direction: column;
     width: 100%;
     font-weight: 100;
     line-height: 1.5rem;
 }
 
 .acct_step {
   display: flex;
 }
 
 .open_acct_text {
     color: #fff;
     margin-left: 5px;
 }
 
 .open_acct_h2_div {
   margin-bottom: 10px;
 }
 
 .open_acct_h2_shared {
   font-weight: 400;
   font-size: 19px;
   color:#5f72bd;
 }
 
 .open_acct_hr {
   border: 2px solid #54d8a3;;
   width: 15%;
   margin-bottom: 3%;
   margin-left: 0;
 }
 
 .open_acct_text a {
   color: #fff;
   text-decoration: none;
   display: inline-block;
   border-radius: 20px;
   font-weight: 300;
   padding: 0.37em 1.2em;
   font-family: inherit;
   transition: all 0.2s;
   font-size: 10px;
   margin-bottom: 10%;
 }
 
 .arrow {
   padding-right: 10px;
 }
 
 .second-img {
   text-align: center;
 }
 
 .second-img > img {
   margin-top: 50px;
   width: 50%;
   height: 20vh;
   margin-left: 5px;
 }
 
 .book_pic {
   margin-left: 70px;
 }
 
 .parag {
     font-size: smaller;
     font-weight: 200;
     margin: 25px 10px 0 0;
     color: #5f72bd;

 }
 
 @media (min-width: 768px) {
   .step {
     width: 90px;
     height: fit-content;
   }
 
   .open_acct_shared {
     flex-direction: row;
   }
 
   .acct_step {
     margin-inline: 7%;
   }
 
   .second-img {
    width: 100%;
    margin-right: 5%;
  }

  .second-img > img {
    width: 100%;
    margin-right: 5%;
  }
   .open_acct_hr {
     width: 8%;
   }
 
 }
 