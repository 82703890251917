.contact {
  background-image: linear-gradient(28deg, #000080, #1035ac);
  padding: 10% 0 6% 5%;
}

.contact > h5 {
  margin-top: 0;
  font-weight: 500;
  font-size: 11px;
  color: #54d8a3;
  line-height: 35px;
  text-transform: uppercase;
}

.contact_h1 {
  color: #fff;
  margin-bottom: 6%;
  font-weight: 300;
}

.support_form {
  text-align: center;
  width: 100%;
}

.support_form > h4 {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #639;
  padding-bottom: 6%;
  padding-top: 10%;
}

.support_form > p {
  font-weight: 200;
  font-size: 14px;
  line-height: 24px;
  color: #639;
}

.support_form > hr {
  font-size: 1px;
  color: rgb(255, 255, 255);
  margin: 3% 33px 0 33px;
}

.form-control {
  padding: 4%;
  width: 42%;
  border: 1px solid #eee;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  margin: 7px;
}

.textarea {
  width: 90%;
  min-height: 160px;
}

::placeholder {
  color: rgb(180, 173, 173);
  font-weight: 300;
}

.sbmt {
  background: linear-gradient(
    135deg,
    #000080 0,
    #1035ac 100%
  );
  color: #fff !important;
  height: 40px;
  margin-bottom: 10%;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  border: 0;
  margin-top: 32px;
}

.faq_h3 {
  color: #857fe9;
  font-size: 15px;
  font-weight: 300;
  line-height: 23px;
  padding-bottom: 18px;
}

.Read {
  width: 90%;
  min-height: 50px;
  background-color: rgb(245, 245, 245);
  display: flex;
  align-items: center;
  padding-left: 20px;
}
.ReadContainer {
  width: 100%;
  height: max-content;
  display: flex;
  align-items: center;
  margin-top: 3%;
  flex-direction: column;
}

.Standard {
  width: 90%;
  margin-top: 5%;
}

.Standard > h5 {
  color: rgb(102, 51, 153);
}

.Standard > p {
  color: rgb(119, 114, 126);
  margin-top: 2%;
  font-size: 15px;
}

.maincontainer {
  height: max-content;
}

@media (min-width: 768px) {
.flex-cont {
/*   display: flex;
  justify-content: space-between; */
/*   flex-direction: row-reverse;
  align-items: center;
  flex-wrap: wrap; */
  margin-top: 10%;
  padding-left: 5%;
}

.support_form {
  width: 50%;
  text-align: start;
}

.support_form > hr {
  margin: 2% 0 10% 0;
}

.support_form > h4 {
  padding-top: 0;
}

.map {
  height: 90vh;
  width: 50%;
}
}