@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'montserrat',  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  .Icons {
    font-size: 25px;
    margin-right: 2%;
  }
  
  @media (max-width: 768px) {
    .Icons{
      font-size: 10px;
    }
  }

.BitconMapDiv {
    width: 100%;
    min-height: 50px;
}

.tradingview-widget-container {
    width: 100%;
}

.IframeCss {
    width: 100%;
    height: 50px;
}